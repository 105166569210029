class Tabs {
  constructor(selector) {
    if (document.querySelectorAll(selector).length) {
      this.allTabs = document.querySelectorAll(selector)
      // this.calcHeight = this.calcHeight.bind(this)
      this.init()
    }
  }

  removeAllActiveTabs(activeTabs) {
    for (let i = 0; i < activeTabs.length; i++) {
      const prevActiveTab = activeTabs[i]
      prevActiveTab.classList.remove('activeTab')
      if (prevActiveTab.classList.contains('tab-panel')) {
        prevActiveTab.classList.remove('enter')
      }
    }
  }

  onTabClick(event, anchor, parent) {
    let activeTabs = parent.querySelectorAll('.activeTab')

    // remove previous active classes
    this.removeAllActiveTabs(activeTabs)

    anchor.classList.add('activeTab')
    const panelID = anchor.dataset.href
    const panel = parent.querySelector(`[data-id="${panelID}"]`)

    for (let i = 0; i < activeTabs.length; i++) {
      if (activeTabs[i] !== panel) activeTabs[i].classList.add('exit')
    }

    panel.classList.remove('exit')
    panel.classList.add('activeTab', 'enter')
  }

  calcHeight(tab, anchor, onLoad = false) {
    let tabContent = tab.querySelector('.tab-content'),
      panel,
      panelContent
    if (onLoad) {
      panel = tab.querySelector('.tab-panel.activeTab')
    } else {
      const panelID = anchor.dataset.href
      panel = tab.querySelector(`[data-id="${panelID}"]`)
    }
    panelContent = panel.querySelector('.tab-panel__content')
    tabContent.style.height =
      panelContent.getBoundingClientRect().height + 'px'
  }

  init() {
    this.allTabs.forEach(tabs => {
      const allAnchors = [...tabs.querySelectorAll('.tab-anchor')]
      allAnchors.forEach(anchor => {
        this.calcHeight(tabs, anchor, true)
        anchor.addEventListener('click', e => {
          e.preventDefault()
          this.calcHeight(tabs, anchor)
          this.onTabClick(e, anchor, tabs)
        })
      })
    })
  }
}

export default Tabs
