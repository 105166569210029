class LazyLoadImages {
  constructor() {
    this.lazyLoadSingle = this.lazyLoadSingle.bind(this)
    this.lazyLoadAll = this.lazyLoadAll.bind(this)
    this.init()
  }

  lazyLoadSingle(imgToLoad) {
    const imgUrl = imgToLoad.dataset.imgSrc
    if (imgToLoad.nodeName === 'IMG') {
      imgToLoad.setAttribute('src', imgUrl)
      imgToLoad.classList.remove('jsLoading')
    } else {
      imgToLoad.style.backgroundImage = `url('${imgUrl}')`
      imgToLoad.classList.remove('jsLoading')
    }
  }

  lazyLoadAll() {
    const allTargets = document.querySelectorAll('.js-img-lazy') || false
    if (allTargets) {
      const imagesToLazyLoad = Array.from(allTargets)
      // const imagesToLazyLoad = [].slice.call(allTargets);
      if (!!window.IntersectionObserver) {
        let observer = new IntersectionObserver(
          (imagesToLazyLoad, observer) => {
            imagesToLazyLoad.forEach(imgToLoad => {
              if (imgToLoad.isIntersecting) {
                this.lazyLoadSingle(imgToLoad.target)
                observer.unobserve(imgToLoad.target)
              }
            })
          },
          { rootMargin: '0px 0px 200px 0px' }
        )
        imagesToLazyLoad.forEach(img => {
          observer.observe(img)
        })
      } else {
        setTimeout(() => {
          imagesToLazyLoad.forEach(imgToLoad => {
            this.lazyLoadSingle(imgToLoad)
          })
        }, 2000)
      }
    } else {
      return false
    }
  }

  init() {
    // console.log('Lazy init')
    this.lazyLoadAll()
  }
}

export default LazyLoadImages
