import { wr_scrollTop } from '../../../helpers/helpers'

export default class Header {
  /**
   * @param {boolean} hasScroll - if true, header will replace classes depending on it's scroll position
   * @returns {false} Header related logic for mobile devices
   */
  constructor(hasScroll) {
    this.header = document.querySelector('.header')
    this.navToggle = document.querySelector('.navbar-toggler')
    this.navMenu = document.querySelector('.navbar-collapse')
    this.backdrop = document.querySelector('.backdrop')
    this.hasScroll = hasScroll
    this.scrollTop
    // TODO: add offset inside constructors
    this.offsetTop = 60
    this.headerScroll = this.headerScroll.bind(this)
    this.init()
  }

  headerScroll() {
    this.scrollTop = wr_scrollTop()

    if (this.scrollTop >= this.offsetTop && window.innerWidth > 767) {
      this.header.classList.add('jsOnScroll')
      document.body.classList.add('jsOnScroll-header')
    } else {
      this.header.classList.remove('jsOnScroll')
      document.body.classList.remove('jsOnScroll-header')
    }
  }

  headerNavToggle() {
    this.navToggle.addEventListener('click', () => {
      // TODO: refactor this to only manipulate body's class
      if (this.navToggle.classList.contains('toggle-on')) {
        document.body.classList.remove('js-menu-open')
        this.backdrop.classList.remove('js-backdropOn')
        this.navToggle.classList.remove('toggle-on')
        this.navMenu.classList.remove('navMenu-on')
      } else {
        document.body.classList.add('js-menu-open')
        this.backdrop.classList.add('js-backdropOn')
        this.navToggle.classList.add('toggle-on')
        this.navMenu.classList.add('navMenu-on')
      }
    })

    this.backdrop.addEventListener('click', () => {
      if (this.navToggle.classList.contains('toggle-on')) {
        document.body.classList.remove('js-menu-open')
        this.backdrop.classList.remove('js-backdropOn')
        this.navToggle.classList.remove('toggle-on')
        this.navMenu.classList.remove('navMenu-on')
      }
    })
  }

  init() {
    this.headerNavToggle()
    if (this.hasScroll) {
      this.headerScroll()
      window.addEventListener('scroll', this.headerScroll)
    }
  }
}
