function scroll(element, offset, duration) {
  let start = null
  const firstPos = window.pageYOffset - offset || document.documentElement.scrollTop - offset
  const target = element && element ? element.getBoundingClientRect().top : document.body.getBoundingClientRect().top
  let pos = 0

  ;(function() {
    var browser = ['ms', 'moz', 'webkit', 'o']

    for (
      let x = 0, length = browser.length;
      x < length && !window.requestAnimationFrame;
      x++
    ) {
      window.requestAnimationFrame =
        window[browser[x] + 'RequestAnimationFrame']
      window.cancelAnimationFrame =
        window[browser[x] + 'CancelAnimationFrame'] ||
        window[browser[x] + 'CancelRequestAnimationFrame']
    }
  })()

  const outQuad = function(n) {
    return n * (2 - n)
  }

  function showAnimation(timestamp) {
    if (!start) {
      start = timestamp || new Date().getTime()
    }

    let elapsed = timestamp - start
    let progress = elapsed / duration
    let easeInPercentage = target > 0 ? +outQuad(progress).toFixed(2) : +outQuad(progress).toFixed(2) * -1

    // if target is 0 (back to top), the position is: current pos + (current pos * percentage of duration)
    // if target > 0 (not back to top), the positon is current pos + (target pos * percentage of duration)
    if (target === 0) {
      pos = firstPos - firstPos * easeInPercentage + offset
    } else if (target > 0) {
      pos = firstPos + target * easeInPercentage + offset
    } else {
      pos = (firstPos - (target - offset) * easeInPercentage) + offset
    }


    window.scroll({
      top: pos,
      left: 0
    })

    if (target > 0) {
      if (target !== 0 && pos >= firstPos + target) {
        cancelAnimationFrame(start)
        pos = 0
      } else {
        window.requestAnimationFrame(showAnimation)
      }
    } else {
      if (
        (target !== 0 && pos <= firstPos + target) ||
        (pos <= offset) ||
        easeInPercentage === -1
      ) {
        cancelAnimationFrame(start)
        pos = 0
      } else {
        window.requestAnimationFrame(showAnimation)
      }
    }
  }

  window.requestAnimationFrame(showAnimation)
}

class SmoothScroll {
  constructor() {
    this.anchors = document.querySelectorAll('[href^="#"]')
    this.init()
  }

  is_numeric(str){
    return /^\d+$/.test(str);
  }

  init() {
    for (let i = 0; i < this.anchors.length; i++) {
      const anchor = this.anchors[i]
      const anchorTarget = anchor.href
      const scrollTargetSec = document.getElementById(
        `${anchor.href.split('#')[1]}`
      );
      const scrollSpeed = anchor.dataset.scrollSpeed
      ? +anchor.dataset.scrollSpeed
      : 600;
      const yOffset = 0;

      if (anchor.dataset.scrollOffset) {
        if (this.is_numeric(anchor.dataset.scrollOffset)) {
          yOffset = +anchor.dataset.scrollOffset
        } else {
          if (document.querySelector(`${anchor.dataset.scrollOffset}`)) {
            yOffset = document.querySelector(`${anchor.dataset.scrollOffset}`).getBoundingClientRect().height
          } else {
            yOffset = 0
          }
        }
      }


      anchor.addEventListener('click', e => {
        e.preventDefault()
        // if href="#something" not only href="#"
        if (anchorTarget.length > 1 && scrollTargetSec) {
          scroll(scrollTargetSec, yOffset, scrollSpeed)
        } else {
          scroll(false, yOffset, scrollSpeed)
        }
      })
    }
  }
}

export default SmoothScroll
