import {
  wr_is_page,
  wr_is_blog,
  wr_is_single,
  wr_is_search_page,
  wr_is_category,
  wr_is_archive,
  wr_is_page_template
} from '../helpers/helpers'

import Polyfills from './global/polyfills/Poyfills'
import Header from './global/UI/Header'
import Dropdowns from './global/UI/Dropdowns'
import Modals from './global/UI/Modals'
import Accordions from './global/UI/Accordions'
import Tabs from './global/UI/Tabs'
import SmoothScroll from './global/core/SmoothScroll'
import LazyLoadImages from './global/core/LazyLoadImages'
import TransformSvgImgsToSvgCode from './global/core/TransformSvgImgsToSvgCode'

class Main {
  constructor() {
    this.init()
  }

  globalScripts() {
    new Polyfills()
    new Dropdowns()
    new Header(true)
    new TransformSvgImgsToSvgCode()
    new Modals()
    // TODO: move selectors inside Classes
    new Accordions('.wrwps-accordion')
    new Tabs('.tabs-wrap')
    new SmoothScroll()
    window.addEventListener('load', () => {
      new LazyLoadImages()
    })
  }

  pageSpecificScripts() {
    if (wr_is_page('home')) {
      // TODO: This is an example; remove
      console.log('We are on homepage')
    }
  }

  blogScripts() {
    if (wr_is_blog()) {
      console.log('This will be execuded on blog feed template')
    }
  }

  singleScripts() {
    if (wr_is_single()) {
      console.log('This is executed on blog single template')
    }

    // XXX: example for a CPT single check
    // if (wr_is_single('locations')) {
    //   console.log('This is executed on blog single template')
    // }
  }

  searchPageScripts() {
    if (wr_is_search_page()) {
      console.log('This is executed on the search page template')
    }
  }

  categoryScripts() {
    if (wr_is_category()) {
      console.log('Executed on all the category page templates')
    }
    // XXX: example showing how to check for a single category
    // if (wr_is_category('education')) {
    //   console.log('Executed on all the "education" category page templates')
    // }
  }

  archiveScripts() {
    if (wr_is_archive()) {
      console.log('Executed on all the archive page templates')
    }
    // XXX: example showing how to check for an archive of a "locations" CPT
    // if (wr_is_archive('locations')) {
    //   console.log('Executed on the "locations" CPT archive page template')
    // }
  }

  templatesScripts() {
    if (wr_is_page_template()) {
      console.log('Will execute on all page templates')
    }
    // XXX: example showing how to check for a specific template
    if (wr_is_page_template('generic')) {
      console.log('Will execute on the "generic" page template')
    }
  }

  init() {
    document.addEventListener('DOMContentLoaded', () => {
      // scripts used on all pages
      this.globalScripts()
      // scripts used on specific pages
      this.pageSpecificScripts()
      // scripts used on specific templates
      this.templatesScripts()
      // scripts used on the blog feed template
      this.blogScripts()
      // scripts used on blog single, and CPT single templates
      this.singleScripts()
      // scripts used on the search page template
      this.searchPageScripts()
      // scripts used on the archive page template
      this.archiveScripts()
      // scripts used on the category page templates
      this.categoryScripts()
    })
  }
}

export default Main
