import axios from 'axios'

/**
 * @desc Replaces <img src="path.svg" class="js-async-svg" /> into SVG code
 * (Custom replacement for SVG inline plugin's code that pulled whole jQuery for one small script)
 */
class TransformSvgImgsToSvgCode {
  constructor() {
    this.svgImages = document.querySelectorAll('img.js-async-svg') || false
    this.fetchSvgCode = this.fetchSvgCode.bind(this)
    this.init()
  }

  fetchSvgCode($selector, svg_url) {
    axios
      .get(svg_url, { responseType: 'text' })
      .then(res => {
        return res.data
      })
      .then(svg_code => {
        $selector.outerHTML = svg_code
      })
      .catch(err => console.log(err))
  }

  transformToCode() {
    const svgsToLazyLoad = Array.from(this.svgImages)
    if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver(
        (svgsToLazyLoad, observer) => {
          svgsToLazyLoad.forEach(SVGtoLoad => {
            if (SVGtoLoad.isIntersecting) {
              let MEDIA_PATH = SVGtoLoad.target.dataset.src
              // this is svg
              SVGtoLoad.target.classList.remove('js-async-svg')
              if (SVGtoLoad.target.classList.contains('jsLoading')) {
                SVGtoLoad.target.classList.remove('jsLoading')
              }
              if (SVGtoLoad.target.dataset.src.endsWith('.svg')) {
                const IS_HTTPS = location.protocol === 'https:'
                const MEDIA_PATH_STARTS_WITH =
                  MEDIA_PATH.startsWith('//') ||
                  MEDIA_PATH.startsWith('http://')
                if (IS_HTTPS && MEDIA_PATH_STARTS_WITH) {
                  if (MEDIA_PATH.startsWith('//')) {
                    MEDIA_PATH = MEDIA_PATH.replace('//', 'https://')
                  } else if (MEDIA_PATH.startsWith('http://')) {
                    MEDIA_PATH = MEDIA_PATH.replace('http://', 'https://')
                  }
                }
                this.fetchSvgCode(SVGtoLoad.target, MEDIA_PATH)
                // this is a regular image
              } else {
                SVGtoLoad.target.src = MEDIA_PATH
              }
              observer.unobserve(SVGtoLoad.target)
            }
          })
        },
        { rootMargin: '0px 0px 300px 0px' }
      )
      svgsToLazyLoad.forEach(img => {
        observer.observe(img)
      })
    } else {
      setTimeout(() => {
        svgsToLazyLoad.forEach(SVGtoLoad => {
          SVGtoLoad.classList.remove('js-async-svg')
          if (SVGtoLoad.classList.contains('jsLoading')) {
            SVGtoLoad.classList.remove('jsLoading')
          }
          const svg_url = SVGtoLoad.dataset.src
          // this is svg
          if (svg_url.endsWith('.svg')) {
            this.fetchSvgCode(SVGtoLoad, svg_url)
          } else {
            // this is a regular image
            SVGtoLoad.target.src = svg_url
          }
        })
      }, 2000)
    }
  }

  init() {
    if (this.svgImages) {
      this.transformToCode()
    } else {
      return false
    }
  }
}

export default TransformSvgImgsToSvgCode
